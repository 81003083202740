define('cartobox-promises-utility/utils/normalize-carto-vectors', ['exports', 'cartobox-promises-utility/utils/carto'], function (exports, _carto) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = normalizeCartoVectors;
  var getVectorTileTemplate = _carto.default.getVectorTileTemplate;
  function normalizeCartoVectors() {
    var pseudoMapboxGlSources = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    // coerce to an array
    var iterable = Ember.isArray(pseudoMapboxGlSources) ? pseudoMapboxGlSources : [pseudoMapboxGlSources];

    // normalize into mapbox-gl source spec
    return Ember.RSVP.Promise.all(iterable.map(function (source) {
      var _Ember$getProperties = Ember.getProperties(source, 'id', 'type', 'minzoom', 'source-layers'),
          id = _Ember$getProperties.id,
          type = _Ember$getProperties.type,
          _Ember$getProperties$ = _Ember$getProperties.minzoom,
          minzoom = _Ember$getProperties$ === undefined ? 0 : _Ember$getProperties$,
          sourceLayers = _Ember$getProperties['source-layers'];

      if (type !== 'cartovector') {
        return new Ember.RSVP.Promise(function (resolve) {
          var _Ember$getProperties2 = Ember.getProperties(source, 'tiles', 'tileSize'),
              tiles = _Ember$getProperties2.tiles,
              tileSize = _Ember$getProperties2.tileSize;

          resolve({
            id: id,
            type: type,
            tiles: tiles,
            tileSize: tileSize
          });
        });
      }

      return getVectorTileTemplate(sourceLayers).then(function (template) {
        return {
          id: id,
          type: 'vector',
          tiles: [template],
          minzoom: minzoom
        };
      });
    }));
  }
});