define('ember-cli-tooltipster/components/tool-tipster', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isHTMLSafe = Ember.String.isHTMLSafe;
  exports.default = Ember.Component.extend({
    tooltipsterInstance: null,

    attributeBindings: ['title'],

    /**
     * Set how tooltip should be activated and closed.
     * Default: 'hover'
     * Options: [hover, click]
     * @type {String}
     */
    triggerEvent: 'hover',

    init: function init() {
      this._super.apply(this, arguments);

      this.set('tooltipsterOptions', ['animation', 'animationDuration', 'arrow', 'content', 'contentAsHTML', 'contentCloning', 'debug', 'delay', 'delayTouch', 'distance', 'IEmin', 'interactive', 'maxWidth', 'minIntersection', 'minWidth', 'plugins', 'repositionOnScroll', 'restoration', 'selfDestruction', 'side', 'timer', 'theme', 'trackerInterval', 'trackOrigin', 'trackTooltip', 'triggerClose', 'triggerOpen', 'updateAnimation', 'viewportAware', 'zIndex']);

      this.set('fnOptions', ['functionInit', 'functionBefore', 'functionReady', 'functionAfter', 'functionFormat', 'functionPosition']);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var options = this._getOptions();
      var componentElement = this.$();
      componentElement.tooltipster(options);
      this.set('tooltipsterInstance', componentElement.tooltipster('instance'));
    },
    _getOptions: function _getOptions() {
      var options = this._getStandardOptions();
      var pluginOptions = this._getPluginOptions();

      for (var option in pluginOptions) {
        options[option] = pluginOptions[option];
      }
      return options;
    },
    _getStandardOptions: function _getStandardOptions() {
      var _this = this;

      var options = {};
      var addonConfig = Ember.getOwner(this).resolveRegistration('config:environment')['ember-cli-tooltipster'] || {};
      var content = this.get('content') || this.get('title');

      this.get('tooltipsterOptions').forEach(function (option) {
        if (!Ember.isEmpty(_this.get(option))) {
          options[option] = _this.get(option);
        }
      });

      options.trigger = this.get('triggerEvent');

      // Handle safe string using ishtmlsafe-polyfill
      if (isHTMLSafe(content)) {
        options.content = content.toString();
      }

      this.get('fnOptions').forEach(function (fn) {
        return options[fn] = Ember.$.proxy(_this[fn], _this);
      });

      if (Ember.isEmpty(Ember.assign)) {
        var localAddonConfig = Ember.merge({}, addonConfig);
        return Ember.merge(localAddonConfig, options);
      }

      return Ember.assign({}, addonConfig, options);
    },
    _getPluginOptions: function _getPluginOptions() {
      var _this2 = this;

      var options = {};
      var pluginOptionKeys = this.get('pluginOptions');
      if (!Ember.isEmpty(pluginOptionKeys)) {
        pluginOptionKeys.forEach(function (pluginOption) {
          return options[pluginOption] = _this2.get(pluginOption);
        });
      }
      return options;
    },


    _onContentDidChange: Ember.observer('content', 'title', function () {
      var _this3 = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var content = _this3.get('content') || _this3.get('title') || null;
        if (isHTMLSafe(content)) {
          content = content.toString();
        }
        if (_this3.get('tooltipsterInstance') !== null) {
          _this3.get('tooltipsterInstance').content(content);
        }
      });
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.$().data('tooltipster-ns')) {
        this.$().tooltipster('destroy');
      }
      this.set('tooltipsterInstance', null);
      this.$().off();
    }
  });
});